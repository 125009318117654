<template>
  <div class="app-main vessels">
    <main>
      <div class="controls">
        <b-navbar class="vessels-filter" type="light">
          <b-navbar-brand class="mb-0 p-0" tag="h1">Vessels</b-navbar-brand>

          <b-navbar-nav class="ml-auto">
            <div class="vessel-count">
              ({{ this.vesselsForDisplay.length }} vessels)
            </div>
            <b-nav-form>
              <b-button
                v-bind:variant="
                  filtersApplied ? 'success' : 'outline-primary'
                "
                size="sm"
                aria-label="Filters"
                v-b-toggle.filter-sidebar
                title="Show Filters"
              >
                <i class="fas fa-filter"></i>
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-navbar>

        <b-alert
          :show="filtersApplied && !vesselsForDisplay.length"
          variant="danger"
          >No vessels were found that match your filter(s).</b-alert
        >

        <b-list-group class="vessels">
          <b-list-group-item
            button
            class="vessel"
            v-bind:class="{ active: selectedVessel && vessel.key === selectedVessel.key }"
            v-for="vessel of vesselsForDisplay"
            :key="vessel.key"
            @click="selectVessel(vessel)"
            >
              <span>{{vessel.label}}</span>
              <span v-if="showVersion">{{" (" + vessel._version + ")"}}</span>
            </b-list-group-item
          >
        </b-list-group>

        <div class="footer">
          <b-button
            class="d-flex align-items-baseline"
            variant="primary"
            size="sm"
            v-bind:disabled="inDm"
            v-b-modal.addVesselModal
          >
            <i class="fas fa-plus mr-1"></i>Add New Vessel
          </b-button>
        </div>
      </div>

      <div class="content">
        <b-navbar
          class="content-nav p-2"
          toggleable="md"
          type="light"
          v-if="selectedVessel && selectedVessel.key"
        >
          <b-navbar-brand class="content-nav-brand mb-0 p-0">
            <div class="content-nav-brand-label">
              <span>{{ selectedVessel.nat }} - {{ selectedVessel.cls }}</span>
              <span v-if="this.showVersion"> ({{selectedVessel._version}})</span>
            </div>

            <b-button variant="outline-primary" 
              :title="cardOptionsVisible ? 'Collapse Card Options' : 'Expand Card Options'"
              :class="cardOptionsVisible ? 'options-toggle' : 'options-toggle collapsed'"
              :aria-expanded="cardOptionsVisible ? 'true' : 'false'"
              aria-controls="vessels-nav-collapse"
              @click="cardOptionsVisible = !cardOptionsVisible"
              size="sm">
              <i class="fas" :class="cardOptionsVisible ? 'fa-angle-up' : 'fa-angle-down'"></i>
            </b-button>
          </b-navbar-brand>

          <b-collapse
            xis-nav
            v-model="cardOptionsVisible"
            id="vessels-nav-collapse"
            class="content-nav-collapse"
          >
            <b-nav-form class="content-nav-form w-100">
              <b-form-checkbox v-model="showDisplayName">Show Display Name</b-form-checkbox>
              <b-form-checkbox v-model="showNegatives">Show Negatives</b-form-checkbox>
              <b-form-checkbox v-model="showZeros">Show Zeros</b-form-checkbox>

              <b-form-radio-group
                id="showThreeGunTemplateToggle"
                v-model="showThreeGunTemplate"
                :options="showThreeGunTemplateOptions"
                title="Vessel Card Template"
                button-variant="outline-primary"
                size="sm"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>

              <b-dropdown
                v-if="!inDm"
                class="d-inline-flex flex-nowrap"
                v-bind:text="cardSizeLabel"
                title="Vessel Card Display Size"
                size="sm"
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-on:click="selectCardSize($event, -1)"
                  v-bind:active="selectedCardSize === -1"
                  >Auto</b-dropdown-item
                >
                <b-dropdown-item
                  v-on:click="selectCardSize($event, 1)"
                  v-bind:active="selectedCardSize === 1"
                  >Full</b-dropdown-item
                >
                <b-dropdown-item
                  v-on:click="selectCardSize($event, 4)"
                  v-bind:active="selectedCardSize === 4"
                  >Print</b-dropdown-item
                >
              </b-dropdown>

              <b-button
                class="d-inline-flex flex-nowrap align-items-baseline"
                variant="outline-primary"
                size="sm"
                title="Edit Vessel Data"
                v-bind:disabled="inDm"
                v-if="isSa && selectedVessel.key"
                v-b-modal.vesselDetailsModal
              >
                <i class="fas fa-pen mr-1"></i>Edit
              </b-button>

              <b-button
                  class="d-inline-flex flex-nowrap align-items-baseline"
                  v-if="isSa && hasDv && selectedVessel.key"
                  variant="outline-primary"
                  size="sm"
                  v-bind:disabled="inDm"
                  v-b-modal.deleteVesselModal
                >
                  <i class="fas fa-trash mr-1"></i>Delete
                </b-button>

              <b-button
                class="d-inline-flex flex-nowrap align-items-baseline"
                variant="outline-primary"
                size="sm"
                title="Print Vessel Card"
                v-bind:disabled="inDm"
                v-on:click="print"
              >
                <i class="fas fa-print mr-1"></i>Print
              </b-button>

              <b-link target="_target"
                class="d-inline-flex flex-nowrap align-items-baseline btn btn-sm btn-outline btn-outline-primary"
                title="Download Vessel Card Image"
                v-bind:href="selectedVesselCardImgData"
                v-bind:download="(selectedVessel.nat + ' - ' + selectedVessel.cls)"
                v-if="isSa && selectedVessel.key"
                v-bind:disabled="!selectedVesselCardImgData"
              >
                <i class="fas fa-download mr-1"></i>Download
              </b-link>
            </b-nav-form>
          </b-collapse>
        </b-navbar>

        <div class="vessel-card-wrapper">
          <div class="h-2 d-flex align-items-center justify-content-center" v-if="!selectedVessel || (selectedVessel && !selectedVessel.key)">
            <b-alert show variant="info">Select a Vessel from the list to view its card.</b-alert>
          </div>

          <div class="h-2 d-flex align-items-center justify-content-center" v-if="showThreeGunTemplateWarning">
            <b-alert show variant="warning">The selected vessel does not have '2 Primary Gun' data. Switch to the '1 Primary Gun' vessel card template to see a more accurate representation of the vessel data.</b-alert>
          </div>

          <VesselCard
            :vessel-key="selectedVessel"
            :resize-to-fit="scaleVesselCard"
            :in-three-gun-mode="showThreeGunTemplate"
            :show-zeros="showZeros"
            :show-negatives="showNegatives"
            :show-display-name="showDisplayName"
            v-bind:class="vesselCardClasses"
            v-show="selectedVessel && selectedVessel.key"
            generate-card-img="true"
            @loaded="vesselCardImageDataLoaded($event)"
          ></VesselCard>
        </div>
      </div>

      <b-sidebar
        id="filter-sidebar"
        v-model="filterPanelOpen"
        title="Vessel Filters"
        bg-variant="light"
        text-variant="dark"
        shadow
        backdrop
      >
        <b-container fluid>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="dataVer">Dataset Version:</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                v-model="filterVersion"
                :options="filterVersionOptions"
              ></b-form-select>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="3">
              <label for="vNat">Nation:</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                v-model="filterNation"
                :options="filterNationOptions"
              ></b-form-select>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="3">
              <label for="vClass">Class:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                id="vClass"
                type="text"
                v-model="filterClass"
                size="sm"
                placeholder="Vessel name/class"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12 d-flex justify-content-end">
              <b-button
                class="mt-2 mr-2"
                variant="primary"
                size="sm"
                v-on:click="closeFilterPanel"
                aria-controls="filter-sidebar"
                :aria-expanded="filterPanelOpen ? 'true' : 'false'"
                >Apply Filters</b-button
              >
              <b-button
                class="mt-2"
                variant="outline-secondary"
                size="sm"
                v-on:click="clearFilters"
                :disabled="!filtersApplied"
                aria-controls="filter-sidebar"
                :aria-expanded="filterPanelOpen ? 'true' : 'false'"
                >Reset Filters</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </b-sidebar>
    </main>

    <VesselDetailsModal
      v-if="!inDm"
      :vessel-key="selectedVessel ? selectedVessel.key : null"
    ></VesselDetailsModal>

    <AddVesselModal v-if="!inDm" :vessel-template="selectedVessel && selectedVessel.key ? selectedVessel : null"></AddVesselModal>

    <b-modal
      id="deleteVesselModal"
      title="Delete Vessel?"
      header-bg-variant="warning"
      @ok="deleteVessel"
      ok-title="Yes"
      ok-variant="outline-secondary"
      cancel-title="No"
      cancel-variant="primary"
      centered
      >Are you sure you want to delete this vessel? This action can not be
      undone.</b-modal
    >
  </div>
</template>

<script>
  import Vue from "vue";
  import VesselCard from "../components/VesselCard";
  import VesselDetailsModal from "../components/VesselDetailsModal";
  import AddVesselModal from "../components/AddVesselModal";

  export default {
    name: "Vessels",
    components: { VesselCard, VesselDetailsModal, AddVesselModal },
    data: function () {
      return {
        selectedCardSize: 4,
        selectedVesselCardImgData: null,
        vessels: [],
        vesselsForDisplay: [],
        filterPanelOpen: false,
        filterVersionOptions: [],
        filterNationOptions: [],
        showVersion: false,
        showThreeGunTemplate: false,
        showDisplayName: false,
        showZeros: false,
        showNegatives: false,
        showThreeGunTemplateOptions: [
          { text: '1 Primary Gun', value: false },
          { text: '2 Primary Guns', value: true }
        ],
        cardOptionsVisible: true
      };
    },
    computed: {
      cardSizeLabel: {
        get() {
          let prefix = "Size",
            result = prefix;
          switch (this.selectedCardSize) {
            case -1:
              result = prefix + ": Auto";
              break;
            case 4:
              result = prefix + ": Print";
              break;
            case 1:
              result = prefix + ": Full";
              break;
            default:
              result = prefix;
          }
          return result;
        },
      },
      filterClass: {
        get() {
          return this.$store.state.vessels.filterVesselClass;
        },
        set(val) {
          this.$store.dispatch("vessels/setVesselClassFilter", val);
        },
      },
      filterNation: {
        get() {
          return this.$store.state.vessels.filterVesselNation;
        },
        set(val) {
          this.$store.dispatch("vessels/setVesselNationFilter", val);
        },
      },
      filterVersion: {
        get() {
          return this.$store.state.vessels.filterDataSetVersion;
        },
        set(val) {
          this.$store.dispatch("vessels/setDataSetVersionFilter", val);
        },
      },
      filtersApplied() {
        let filterClassApplied =
          typeof this.filterClass === "string" && this.filterClass !== "";
        let filterNationApplied =
          typeof this.filterNation === "string" && this.filterNation !== "";
        let filterVersionApplied =
          typeof this.filterVersion === "string" && this.filterVersion !== "";

        return filterClassApplied || filterNationApplied || filterVersionApplied;
      },
      inDm: {
        get() {
          return this.$store.state.user.inDm;
        },
      },
      isSa: {
        get() {
          return this.$store.state.user.isSa;
        },
      },
      hasDv: {
        get() {
          return this.$store.state.user.hasDv;
        },
      },
      scaleVesselCard: function () {
        return this.selectedCardSize === -1;
      },
      selectedVessel: function () {
        return this.$store.state.vessels.selected;
      },
      vesselCardClasses: function () {
        return {
          'default-print': this.selectedCardSize === 4
        };
      },
      selectedVesselLabel: function () {
        return this.selectedVessel
          ? this.selectedVessel.nat +
              " - " +
              this.selectedVessel.cls +
              " (" +
              this.selectedVessel.version +
              ")"
          : "";
      },
      showThreeGunTemplateWarning: function() {
        return this.selectedVessel && !Object.prototype.hasOwnProperty.call(this.selectedVessel, 'sat') && !!this.showThreeGunTemplate;
      }
    },
    methods: {
      applyFilters(filterOptions) {
        this.vesselsForDisplay = Vue.$vesselsService.filterVesselsMetadata(
          this.vessels,
          filterOptions
        );
      },
      clearFilters() {
        this.$store.dispatch("vessels/setVesselClassFilter", "");
        this.$store.dispatch("vessels/setVesselNationFilter", "");
        this.$store.dispatch("vessels/setDataSetVersionFilter", "");
        this.applyFilters(null);
        this.filterPanelOpen = false;
      },
      closeFilterPanel() {
        this.filterPanelOpen = false;
      },
      deleteVessel() {
        if (!this.inDm) {
          let self = this;
          Vue.$vesselsService.deleteVessel(this.selectedVessel).then((/*resp*/) => {
            self.selectVessel(null);
          });
        }
      },
      print() {
        if (!this.inDm) {
          this.$store.dispatch("print/openPrintPreview", {
            vessels: [Object.assign({}, this.$store.state.vessels.selected, { vesselCardTemplate: this.showThreeGunTemplate ? '3-gun' : '2-gun' })],
            mode: "vessel-card",
            vesselCardShowZeros: this.showZeros,
            vesselCardShowNegatives: this.showNegatives,
            vesselCardShowDisplayName: this.showDisplayName
          });
        }
      },
      selectCardSize(event, cardSize) {
        event.preventDefault();
        this.selectedCardSize = cardSize;
      },
      selectVessel(vessel) {
        this.$store.dispatch("vessels/setSelected", vessel);
      },
      updateNationsFilterOptions(sourceList) {
        let nations = Vue.$utilService.getUniqueByKey(sourceList, "nat");
        this.filterNationOptions = [
          { value: "", text: "Select a nation" },
        ].concat(nations);
      },
      vesselCardImageDataLoaded(vesselCardImageLoadedEventData) {
        this.selectedVesselCardImgData = vesselCardImageLoadedEventData;
      }
    },
    watch: {
      filterClass: function (newVal, oldVal) {
        if (newVal !== oldVal && typeof newVal === "string" && newVal.length) {
          this.applyFilters({
            vesselClass: newVal,
            vesselNation: this.filterNation,
            dataSetVersion: this.filterVersion,
          });
        }
      },
      filterNation: function (newVal, oldVal) {
        if (newVal !== oldVal && typeof newVal === "string" && newVal.length) {
          this.applyFilters({
            vesselClass: this.filterClass,
            vesselNation: newVal,
            dataSetVersion: this.filterVersion,
          });
        }
      },
      filterVersion: function (newVal, oldVal) {
        if (newVal !== oldVal && typeof newVal === "string" && newVal.length) {
          this.applyFilters({
            vesselClass: this.filterClass,
            vesselNation: this.filterNation,
            dataSetVersion: newVal,
          });
        }
      },
      selectedVessel: function(newVal, oldVal) {
        if(newVal !== oldVal) {
          this.selectedVesselCardImgData = null;
        }
      }
    },
    created() {
      let self = this;
      // https://github.com/vuejs/vue-rx
      this.$subscribeTo(Vue.$vesselsService.vesselsMetadataSubject, function (vMetadata) {
        let allVessels = Array.isArray(vMetadata) ? vMetadata : [];
        self.updateNationsFilterOptions(allVessels);
        self.vessels = allVessels;
        self.filterVersionOptions = Vue.$vesselsService.getVesselsDataVersions();
        self.applyFilters({
          vesselClass: self.filterClass,
          vesselNation: self.filterNation,
          dataSetVersion: self.filterVersion,
        });
      });
    }
  };
</script>

<style lang="scss">
  @import "node_modules/bootstrap/scss/functions";
  @import "node_modules/bootstrap/scss/variables";
  @import "node_modules/bootstrap/scss/mixins/_breakpoints";

  @import "../assets/bsbw.globals.scss";

  .vessels {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    .vessel-card-instructions {
      font-size: 0.9em;
    }

    main {
      flex: 1 1 100%;
      display: flex;
      height: 100%;
      min-height: 0;

      .controls {
        flex: 0 0 350px;
        display: flex;
        flex-direction: column;
        background-color: $controlBackground;
        border-right: $navBorder;

        .vessels-filter {
          flex: 0 0 auto;
          padding: 0.5rem;
          border-bottom: $navBorder;

          .vessel-count {
            font-size: 0.8rem;
            margin: 0.4rem 0.5rem 0 0;
          }
        }

        .alert {
          margin: 10px;
        }

        .vessels {
          flex: 1 1 100%;
          padding: 0.5rem;
          margin: 0;
          list-style-type: none;
          overflow-y: auto;

          .vessel {
            display: flex;
            align-items: center;
            min-height: 58px;
            padding: 1rem;

            &.active {
              color: #fff;
              background-color: $bsbw_blue_800;
            }

            &:not(.active):hover {
              color: #000;
              background-color: $bsbw_blue_400;
              cursor: pointer;
            }

            .vessel-name {
              flex: 1 1 auto;
            }
          }
        }

        .footer {
          flex: 0 0 auto;
          display: flex;
          justify-content: center;
          padding: 10px;
          border-top: $navBorder;
        }
      }

      .content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        min-width: 400px;

        &.scaled .vessel-card {
          transform: scale(0.8);
          transform-origin: 0 0;
        }

        nav {
          flex: 0 0 auto;

          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: stretch;
          flex-wrap: wrap;
          gap: 0.5rem;

          background-color: $controlBackground;
          border-bottom: $navBorder;

          .content-nav-brand {
            flex: 0 0 auto;
            display: flex;
            margin: 0;

            .content-nav-brand-label {
              flex: 1 1 auto;
              white-space: normal;
            }

            .options-toggle {
              flex: 0 0 auto;
            }
          }

          .content-nav-collapse {
            flex: 0 0 auto;

            .form-inline {
              display: flex;
              justify-content: flex-end;
              gap: 0.5rem;
            }
          }

          @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: stretch;

            .content-nav-brand {
              align-items: flex-start;

              .content-nav-brand-label {
                flex: 1 1 auto;
                white-space: normal;
              }
            }

            .content-nav-collapse {
              margin-top: 6px;
            }
          }
        }

        .vessel-card-wrapper {
          flex: 1 1 auto;
          overflow: auto;
          padding: 20px;
        }
      }
    }
  }
</style>
